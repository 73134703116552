import React, { useEffect, useState } from "react"
import { Column, Title } from "rbx"

import SEO from "src/components/seo"
import WorkPlayer from "src/components/works/player"

const axios = require("axios")

const WorksItemPage = ({ id, metadata }) => {
  const { worksApiEndpointUrl } = metadata
  const [work, setWork] = useState([])
  useEffect(() => {
    axios
      .get(`${worksApiEndpointUrl}/${id}`)
      .then(response => {
        setWork(response.data)
      })
      .catch(e => {})
  }, [worksApiEndpointUrl, id])

  return (
    <>
      <SEO
        title={`${work.participant_name} читает стихи на #ГолосПамяти`}
        author={work.participant_name}
        path={`/works/${work.work_id}`}
      />
      <Column.Group>
        <Column size={8}>
          <Title>{work.participant_name}</Title>
          <Title subtitle>
            «{work.creation_title}», {work.creation_author}
          </Title>
          <WorkPlayer work={work} />
        </Column>
        <Column size={4}></Column>
      </Column.Group>
    </>
  )
}

export default WorksItemPage
