import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import {
  Button,
  Content,
  Generic,
  Input,
  Progress,
  Title,
  Field,
  Control,
  Icon,
} from "rbx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

import SEO from "src/components/seo"
import WorksListItem from "./listItem"
import WorksStats from "./listStats"

const axios = require("axios")

class WorksList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      empty: false,
      page: 1,
      works: [],
      worksCount: 0,
      hasMoreItems: true,
      nextHref: null,
      keywords: props.keywords_to_search,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.keywords_to_search !== this.props.keywords_to_search &&
      prevProps.search_attempt !== this.props.search_attempt
    ) {
      this.setState({
        ...this.state,
        page: 1,
        works: [],
        worksCount: 0,
        hasMoreItems: true,
        nextHref: null,
        keywords: this.props.keywords_to_search || "",
      })
    }
  }

  fetchWorks(page) {
    const { worksApiEndpointUrl, worksPortionSize } = this.props.metadata
    page = this.state.page

    let url = `${worksApiEndpointUrl}?offset=${(page - 1) *
      worksPortionSize}&limit=${worksPortionSize}`
    if (
      this.state.keywords &&
      this.state.keywords !== "" &&
      this.state.keywords.length >= 3
    ) {
      url += `&keywords=${encodeURI(this.state.keywords)}`
    }
    console.log(url)

    axios
      .get(url)
      .then(response => {
        if (200 === response.status) {
          this.setState({
            ...this.state,
            empty: this.state.works.concat(response.data.items).length === 0,
            page: this.state.page + 1,
            works: this.state.works.concat(response.data.items),
            worksCount: response.data.count,
            hasMoreItems: page * worksPortionSize <= response.data.count,
          })
          console.log(
            `page * worksPortionSize = ${page *
              worksPortionSize}, this.state.worksCount = ${
              this.state.worksCount
            }`
          )
        }
      })
      .catch(e => {})
  }

  render() {
    const loader = <Progress />
    let items = []
    this.state.works.forEach((work, index) => {
      items.push(<WorksListItem key={index} work={work} index={index} />)
    })

    return (
      <Generic>
        {this.state.empty && (
          <Generic textAlign="centered">
            По вашему запросу не найдено ни одного участника.
          </Generic>
        )}
        {!this.state.empty && this.state.keywords !== "" && (
          <Generic textAlign="centered" style={{ marginBottom: "3rem" }}>
            Найдено участников по запросу:{" "}
            <strong>{this.state.worksCount}</strong>
          </Generic>
        )}
        <InfiniteScroll
          pageStart={0}
          loadMore={this.fetchWorks.bind(this)}
          hasMore={this.state.hasMoreItems}
          loader={loader}
        >
          {items}
        </InfiniteScroll>
      </Generic>
    )
  }
}

class WorksListInfinitePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      keywords: "",
      keywords_to_search: "",
      search_attempt: 0,
      typing: false,
      typingTimeout: 0,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this)
    this.handleInputKeyPress = this.handleInputKeyPress.bind(this)
    this.handleClearButtonClick = this.handleClearButtonClick.bind(this)
  }

  handleInputChange(e) {
    this.setState({
      ...this.state,
      keywords: e.target.value,
    })
  }

  handleInputKeyPress(e) {
    if (e.key === "Enter") {
      this.search()
    }
  }

  handleSearchButtonClick(e) {
    this.search()
  }

  handleClearButtonClick(e) {
    this.setState({
      ...this.state,
      search_attempt: this.state.search_attempt + 1,
      keywords: "",
      keywords_to_search: "",
    })
  }

  search() {
    this.setState({
      ...this.state,
      search_attempt: this.state.search_attempt + 1,
      keywords: String(this.state.keywords).trim(),
      keywords_to_search: String(this.state.keywords).trim(),
    })
  }

  render() {
    const searchButton =
      this.state.keywords === "" ? (
        <Button
          disabled
          rounded
          color="danger"
          size="medium"
          onClick={this.handleSearchButtonClick}
        >
          Найти
        </Button>
      ) : (
        <Button
          rounded
          color="danger"
          size="medium"
          onClick={this.handleSearchButtonClick}
        >
          Найти
        </Button>
      )

    const clearButton =
      this.state.keywords === "" ? (
        <Button size="medium">
          <Icon color="light">
            <FontAwesomeIcon icon={faTimes} />
          </Icon>
        </Button>
      ) : (
        <Button size="medium" onClick={this.handleClearButtonClick}>
          <Icon color="dark">
            <FontAwesomeIcon icon={faTimes} />
          </Icon>
        </Button>
      )

    return (
      <Content>
        <SEO title="Список участников" path="/works" />
        <Title textAlign="centered">Список участников</Title>
        <WorksStats
          statsApiEndpointUrl={this.props.metadata.statsApiEndpointUrl}
        />
        <Generic style={{ padding: "2rem 0" }}>
          <Field kind="addons" align="centered">
            <Control>
              <Input
                type="text"
                rounded
                placeholder="Поиск участников"
                size="medium"
                value={this.state.keywords}
                onChange={this.handleInputChange}
                onKeyPress={this.handleInputKeyPress}
              />
            </Control>
            <Control>{clearButton}</Control>
            <Control>{searchButton}</Control>
          </Field>
        </Generic>
        <WorksList
          keywords_to_search={this.state.keywords_to_search}
          search_attempt={this.state.search_attempt}
          metadata={this.props.metadata}
        />
      </Content>
    )
  }
}

export default WorksListInfinitePage
