import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import {
  faYoutube,
  faFacebook,
  faInstagram,
  faTwitter,
  faOdnoklassniki,
  faVk,
} from "@fortawesome/free-brands-svg-icons"

const WorksListSourceIcon = ({ source, size }) => {
  switch (source) {
    case "YOUTUBE":
      return <FontAwesomeIcon icon={faYoutube} size={size} />
    case "FACEBOOK":
      return <FontAwesomeIcon icon={faFacebook} size={size} />
    case "VKONTAKTE":
      return <FontAwesomeIcon icon={faVk} size={size} />
    case "OK":
      return <FontAwesomeIcon icon={faOdnoklassniki} size={size} />
    case "INSTAGRAM":
      return <FontAwesomeIcon icon={faInstagram} size={size} />
    case "TWITTER":
      return <FontAwesomeIcon icon={faTwitter} size={size} />
    default:
      return <FontAwesomeIcon icon={faQuestionCircle} size={size} />
  }
}

export default WorksListSourceIcon
