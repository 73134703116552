import React from "react"
import { Router } from "@reach/router"
import { graphql, useStaticQuery, withPrefix } from "gatsby"

import Layout from "src/layouts/inner"
import WorksListInfinitePage from "src/components/works/listInfinite"
import WorksItemPage from "src/components/works/item"

const WorksPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          statsApiEndpointUrl
          worksApiEndpointUrl
          worksPortionSize
        }
      }
    }
  `)
  // console.log(data.site.siteMetadata)

  return (
    <Layout>
      <Router basepath="/works">
        <WorksListInfinitePage path="/" metadata={data.site.siteMetadata} />
        <WorksItemPage
          path={withPrefix("/:id")}
          metadata={data.site.siteMetadata}
        />
      </Router>
    </Layout>
  )
}

export default WorksPage
