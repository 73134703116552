import React from "react"
import { Link } from "gatsby"
import { Column } from "rbx"

import WorksListSourceIcon from "src/components/works/sourceIcon"

const WorksListItem = ({ work, index }) => {
  const creation_title =
    "VKONTAKTE" !== work.url_source ? (
      <Link to={"/works/" + work.work_id}>{work.creation_title}</Link>
    ) : (
      work.creation_title
    )
  const responsive = {
    mobile: {
      textAlign: {
        only: true,
        value: "left",
      },
    },
    tablet: {
      textAlign: {
        value: "right",
      },
    },
  }

  return (
    <Column.Group gapSize={2}>
      <Column size={1} responsive={responsive}>
        {index + 1}
      </Column>
      <Column size={6}>
        <strong>{work.participant_name}</strong>
        <br />
        <span className="works_list__location">
          {work.participant_country}, {work.participant_region},{" "}
          {work.participant_locality}
        </span>
      </Column>
      <Column size={4}>
        {creation_title}
        <br />
        <span className="works_list__author">{work.creation_author}</span>
      </Column>
      <Column size={1}>
        <a href={work.url} target="_blank" rel="noopener noreferrer">
          <WorksListSourceIcon source={work.url_source} size="2x" />
        </a>
      </Column>
    </Column.Group>
  )
}

export default WorksListItem
