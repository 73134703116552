import React from "react"
import ReactPlayer from "react-player"
import { TwitterTweetEmbed } from "react-twitter-embed"
import InstagramEmbed from "react-instagram-embed"

const WorkPlayer = ({ work }) => {
  const { url, url_source, video_external_id } = work

  switch (url_source) {
    case "YOUTUBE":
      return (
        <div className="player-wrapper-inner">
          <ReactPlayer
            className="video-player"
            url={work.url}
            width="100%"
            height="100%"
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
          />
        </div>
      )

    case "FACEBOOK":
      return (
        <div className="player-wrapper-inner">
          <ReactPlayer
            className="video-player"
            url={work.url}
            width="100%"
            height="100%"
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
          />
        </div>
      )

    case "VKONTAKTE":
      return (
        <div className="player-wrapper-inner">
          {/* <iframe
            src={
              "https://vk.com/video_ext.php?oid=946214&id=158820213&hash=de77bd23d110bfc3"
            }
            style={{ width: "100%", hight: "100%" }}
            frameborder="0"
            allowfullscreen
          ></iframe> */}
        </div>
      )

    case "OK":
      return (
        <div className="player-wrapper-inner">
          <iframe
            src={"https://ok.ru/videoembed/" + video_external_id}
            style={{ width: "100%", hight: "100%" }}
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      )

    case "INSTAGRAM":
      return (
        <div className="player-wrapper-inner-instagram">
          <InstagramEmbed id="instagram-player" url={url} />
        </div>
      )

    case "TWITTER":
      return <TwitterTweetEmbed tweetId={video_external_id} />

    default:
      return <></>
  }
}

export default WorkPlayer
