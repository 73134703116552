import React, { useState, useEffect } from "react"

import { Title } from "rbx"

const axios = require("axios")

const WorksStats = ({ statsApiEndpointUrl }) => {
  const [worksTotal, setWorksTotal] = useState(0)

  useEffect(() => {
    axios
      .get(statsApiEndpointUrl)
      .then(response => {
        if (200 === response.status) {
          setWorksTotal(response.data.works_count)
        }
      })
      .catch(e => {})
  }, [statsApiEndpointUrl])

  return (
    <Title subtitle size={5} textAlign="centered">
      Общее число участников инициативы #ГолосПамяти на данный момент —{" "}
      {worksTotal}
    </Title>
  )
}

export default WorksStats
